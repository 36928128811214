<template>
    <section>
        <Header />
        <div class="container">

            <WAppIcon link="https://wa.link/wja6xv" />

            <main class="principal">
                <picture class="only-pc aos">
                    <source srcset="../../assets/landing/background_principal_pc.webp" type="image/webp">
                    <img src="../../assets/landing/background_principal_pc.png" alt="Frame decorativo">
                </picture>
                <picture class="only-mov aos">
                    <source srcset="../../assets/landing/background_principal_mov.webp" type="image/webp">
                    <img src="../../assets/landing/background_principal_mov.png" alt="Frame decorativo">
                </picture>
                <h1 class="aos">Agencia de Marketing en Guadalajara</h1>
                <p class="aos">
                    Nos especializamos en desarrollar campañas de <strong>Marketing Digital en Guadalajara</strong>, diseñadas para
                     comunicar estratégicamente tu marca o servicio de manera creativa. Nuestro objetivo es ayudarte
                      a alcanzar a tu público objetivo, aumentar la visibilidad y generar un crecimiento en ventas.
                      <br><br>
                    Contamos con un equipo multidisciplinario altamente capacitado, compuesto por community managers,
                     creativos, diseñadores y analistas. Este equipo trabaja en conjunto para asegurar que cada campaña
                      esté alineada con tus metas comerciales y los intereses de tu audiencia.
                      <br><br>
                    Utilizamos herramientas avanzadas para segmentar y analizar el mercado objetivo, personalizando
                     las estrategias para maximizar el retorno de inversión y resultados. Desde la planificación hasta
                      la ejecución y el análisis, estamos comprometidos con llevar tu marca al éxito.
                </p>
            </main>
            <section class="medio">
                <picture class="only-pc">
                    <source srcset="" type="image/webp">
                    <img src="../../assets/landingPMPD/firstSection.png" alt="VP" class="beforeMedio">
                </picture>
                <picture class="only-mov">
                    <source srcset="" type="image/webp">
                    <img src="../../assets/landingPMPD/firstSection.png" alt="VP" class="beforeMedio">
                </picture>
                <h3 class="beforeMedioH">
                    Nuestros Servicios
                </h3>
                <div class="aos">
                    <h2 class="medio__titulo">Social Media:</h2> <br>

                    <ul>

                        <li>
                            <p><strong>Content Creation </strong> Creamos contenido atractivo y relevante para tus redes sociales,
                                 alineado con la identidad de tu marca.</p><br>
                        </li>
                        <li>
                            <p><strong>Community Management: </strong> Gestionamos y fomentamos la interacción con tu comunidad en 
                                redes sociales para mantener un vínculo sólido con tus seguidores.</p><br>
                        </li>
                        <li>
                            <p><strong>Influencer Marketing: </strong> Colaboramos con influencers estratégicos para amplificar el
                                 alcance de tu marca y generar confianza entre tu audiencia.</p><br>
                        </li>
                        <li>
                            <p><strong>Trends y Estrategia: </strong>  Analizamos las últimas tendencias del mercado y desarrollamos
                                 estrategias innovadoras para mantener tu marca a la vanguardia.
                            </p><br>
                        </li>
                    </ul>
                </div>
            </section>
                <div class="medio_cuadricula aos">
                    <div>
                        <h2 class="medio__titulo">Performance Marketing:</h2> <br>
                        <ul>
                            <li>
                                <p><strong>Meta Ads:</strong> Diseñamos y gestionamos campañas efectivas en plataformas de Meta (Facebook e Instagram)
                                     para maximizar tu alcance y conversiones.
                                </p><br>
                            </li>
                            <li>
                                <p><strong>Google Ads:</strong> Optimizamos campañas en Google Ads para aumentar la visibilidad de tu marca en 
                                    búsquedas relevantes y atraer tráfico de calidad.
                                </p><br>
                            </li>
                            <li>
                                <p><strong>YouTube Ads:</strong> Creamos anuncios impactantes en YouTube para captar la atención de tu audiencia 
                                    y generar mayor engagement con tu marca.
                                </p><br>
                            </li>
                        </ul>
                        <br class="only-pc"><br class="only-pc">
                    </div>
                    <div>
                        <h2 class="medio__titulo">SEO en Guadalajara</h2><br>

                        <ul>
                            <li>
                                <p><strong>Analytics: </strong> Utilizamos herramientas avanzadas de análisis para medir y 
                                    mejorar el rendimiento de tu sitio web.</p> <br>
                            </li>
                            <li>
                                <p><strong>Optimización de Keywords: </strong> Identificamos y optimizamos las palabras clave 
                                    más relevantes para mejorar tu posicionamiento en los motores de búsqueda.</p><br>
                            </li>
                            <li>
                                <p><strong>Backlinks: </strong> Desarrollamos estrategias de backlinks de calidad para aumentar
                                     la autoridad de tu sitio web.</p><br>
                            </li>
                            <li>
                                <p><strong>Ads: </strong> Integramos campañas de publicidad en buscadores para complementar 
                                    tu estrategia SEO y maximizar los resultados.</p>
                            </li>
                        </ul>
                        <br>

                        <h3 class="medio_cuadriculaH3">Trabajemos <br class="only-mov">juntos, <a href="https://wa.link/wja6xv">cotiza aquí</a></h3>  

                    </div>
                    <picture class="only-mov">
                        <img src="../../assets/landingPMPD/phone.png" alt="shawarma" class="shawarma">
                    </picture>
                </div>
            <section class="proyectos">
                <h2 class="aos only-pc">Conoce un poco <br> de nuestro trabajo en redes:</h2>
                <h2 class="aos only-mov ph2-mv">Conoce un poco  <span> de nuestro trabajo en redes:</span></h2>
                <div class="proyectos__galeria">
                    <button class="aos" v-for="(project, index) in projects" :key="index"
                        @click="navAndDialog(project.link, project.imageWebp)" :aria-label="project.title">
                        <picture>
                            <source :srcset="project.imageWebp" type="image/webp">
                            <img :src="project.imagePng" :alt="project.title">
                        </picture>
                    </button>
                </div>
                <h2 class="aos">¿Te interesa conocer lo que podemos hacer por ti?</h2>
                <p class="ultimoParrafo aos">Déjanos aquí la información y con gusto te contactamos.</p>

                <form class="aos formulario" @submit.prevent="contactoLanding()">
                    <div class="campoName">
                        <label for="form__name">Nombre</label>
                        <input type="text" id="form__name" v-model="name">
                    </div>
                    <div class="campoEmail">
                        <label for="form__email">Correo Electrónico</label>
                        <input type="email" id="form__email" v-model="email">
                    </div >
                    <div class="campoPhone">
                        <label for="form__phone">Teléfono</label>
                        <input type="number" v-model="phone">
                    </div>
                    <div class="campoMessage">
                        <label for="form__message">Mensaje</label>
                        <textarea id="form__message" v-model="message"></textarea>
                    </div>
                    <div class="campoBtns">
                        <button type="submit">
                            <p>
                                Enviar Mensaje
                            </p>
                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"
                                class="only-pc">
                                <circle cx="24" cy="24" r="24" fill="#987EF3" />
                                <g clip-path="url(#clip0_137_399)">
                                    <path
                                        d="M20.592 26.4954C20.4027 26.3414 20.2186 26.1848 20.0274 26.0399C18.3003 24.7329 16.5649 23.4382 14.8538 22.1116C14.6418 21.9478 14.5554 21.6188 14.4107 21.3669C14.6582 21.2636 14.9005 21.089 15.1558 21.0686C20.9931 20.5774 26.8324 20.0967 32.6719 19.6388C33.038 19.6102 33.4206 19.7784 33.796 19.855C33.6243 20.1794 33.5068 20.5536 33.273 20.8224C29.425 25.2399 25.5633 29.6462 21.6938 34.0434C21.5236 34.2358 21.2403 34.326 21.0093 34.4653C20.893 34.1991 20.6807 33.932 20.6759 33.6653C20.6347 31.5008 20.6337 29.3357 20.6171 27.1718C20.6153 26.9529 20.6011 26.7353 20.592 26.4954ZM31.0489 20.8108C31.0355 20.7584 31.0215 20.7081 31.0081 20.6558C26.0791 21.0602 21.1508 21.4659 16.2226 21.8716C17.6536 23.1375 19.115 24.2191 20.5455 25.3392C21.003 25.6977 21.3627 25.7561 21.8979 25.4782C24.7606 23.9967 27.6458 22.5629 30.523 21.1102C30.7038 21.0191 30.874 20.9103 31.0489 20.8108ZM31.4886 21.526C31.3506 21.4643 31.3252 21.4427 31.3123 21.4492C28.1515 23.03 24.99 24.6096 21.8352 26.2055C21.6905 26.2783 21.5217 26.4831 21.521 26.6295C21.5103 28.6332 21.5269 30.6395 21.5376 32.8818C24.9426 28.9959 28.1967 25.2828 31.4886 21.526Z"
                                        fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_137_399">
                                        <rect width="18" height="14.6667" fill="white"
                                            transform="translate(14.4043 21.3701) rotate(-26.7378)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </button>

                        <p class="form__status">{{ statusForm }}</p>
                    </div>
                </form>
            </section>
            <dialog @click="closeModal()">
                <img :src="imageSelected" alt="Imagen en galería">
            </dialog>
        </div>
        <Footer />
    </section>
</template>
<script>
import Header from '../../components/on-site/Header.vue';

import WAppIcon from "../../components/on-site/WAppIcon";

import Footer from '../../components/on-site/Footer.vue';
export default {
    components: {
        Header,
        Footer,

        WAppIcon,

    },

    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            statusForm: '',
            imageSelected: null,
            projects: [
                {
                    imageWebp: require('../../assets/landingsPublicidadDigital/img1.webp'),
                    imagePng: require('../../assets/landingsPublicidadDigital/img1.png'),
                    link: false,
                    title: ""
                },
                {
                    imageWebp: require('../../assets/landingsPublicidadDigital/3&3_GRILL.webp'),
                    imagePng: require('../../assets/landingsPublicidadDigital/3&3_GRILL.png'),
                    link: false,
                    title: "3&3 Grill"
                },
                {
                    imageWebp: require('../../assets/landingsPublicidadDigital/ViajesPima.webp'),
                    imagePng: require('../../assets/landingsPublicidadDigital/ViajesPima.png'),
                    link: false,
                    title: "Viajes Pima"
                },
                {
                    imageWebp: require('../../assets/landingsPublicidadDigital/CirculoLlantero.webp'),
                    imagePng: require('../../assets/landingsPublicidadDigital/CirculoLlantero.png'),
                    link: false,
                    title: "Circulo Llantero"
                },
                {
                    imageWebp: require('../../assets/landingsPublicidadDigital/Bocabo.webp'),
                    imagePng: require('../../assets/landingsPublicidadDigital/Bocabo.png'),
                    link: false,
                    title: "Bocabo"
                },
                {
                    imageWebp: require('../../assets/landingsPublicidadDigital/LaMilla.webp'),
                    imagePng: require('../../assets/landingsPublicidadDigital/LaMilla.png'),
                    link: false,
                    title: "La Milla"
                },
            ],
        }
    },

    mounted() {
        let animateOnScroll = () => {
            const allAos = document.querySelectorAll(".aos");

            let allHaveVisibleClass = true;

            allAos.forEach((elemento, index) => {
                let { top, height, bottom } = elemento.getBoundingClientRect();

                if (window.getComputedStyle(elemento).display === 'none'
                    || bottom > 0 && bottom < window.innerHeight
                    || height >= window.innerHeight && top <= 0) {
                    elemento.classList.add("visible");
                }

                if (!elemento.classList.contains("visible")) {
                    allHaveVisibleClass = false;
                }

            });

            if (allHaveVisibleClass) window.removeEventListener('scroll', animateOnScroll)

        }

        window.addEventListener('scroll', animateOnScroll);
        animateOnScroll()
    },

    methods: {
        async contactoLanding() {

            if (this.name === '' || this.name === null || this.name === undefined) {
                this.statusForm = "Campo Nombre no válido";
                return ''
            }

            if (this.email === '' || this.email === null || this.email === undefined) {
                this.statusForm = "Campo Correo Electrónico no válido";
                return ''
            }

            if (this.phone === '' || this.phone === null || this.phone === undefined) {
                this.statusForm = "Campo Teléfono no válido";
                return ''
            }

            if (this.message === '' || this.message === null || this.message === undefined) {
                this.statusForm = "Campo Mensaje no válido";
                return ''
            }

            let data = {
                name: this.name,
                phone: this.phone,
                email: this.email,
                mensaje: this.message,
                from: "landing naming & branding",
            };

            let response = await this.$store.dispatch(
                "admin/enviarMailContacto",
                data
            );

            if (response.status === 'success') {
                this.limpieza();
            } else {
                this.statusForm = "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
            }
        },

        limpieza() {
            this.name = '';
            this.phone = '';
            this.email = '';
            this.message = '';

            this.statusForm = 'Mensaje enviado con éxito';

            setTimeout(() => {
                this.statusForm = '';
            }, 3000);
        },

        navAndDialog(link, image) {
            if (link) {
                window.location.href = link;
            } else {
                this.imageSelected = image;
                this.openModal();
            }
        },

        openModal() {
            const modal = document.querySelector("dialog");
            modal.showModal();
        },

        closeModal() {
            const modal = document.querySelector("dialog");
            this.imageSelected = null;
            modal.close();
        }
    },

    metaInfo: {

        title: 'Agencia de Marketing en Guadalajara',  
        meta: [
            {
                name: 'description',
                content: 'Nuestros servicios incluyen social media, estrategias de SEO y performance marketing, todo para llevar al éxito de tu marca.'


            }
        ]
    },

}
</script>
<style scoped>
@import "./stylesMain.css";
</style>